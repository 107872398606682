import { Global, Collection, Jobs } from '@sourceflow-uk/sourceflow-sdk';
import SeoHead from '../components/SeoHead';
import LatestAwards from '../components/LatestAwards';
import LatestNews from '../components/LatestNews';
import MainBanner from '../components/MainBanner';
import LatestJobs from '../components/LatestJobs';
import ImageWithVideoBanner from '../components/ImageWithVideoBanner';
import MultiColumnCTA from '../components/MultiColumnCTA';
import GreatRecruitersWidget from '../components/GreatRecruitersWidget';
import ImageWithTextWithOverlay from '../components/ImageWithTextWithOverlay';

export default function Home({
	global,
	all_awards,
	all_news,
	all_jobs,
	all_solutions,
	all_sectors
}) {
	return (
		<>
			<SeoHead />
			<MainBanner
				global={global}
				pathPrefix={`homepage.main_banner`}
				customClass={``}
				content={
					<>
						<h1>Trusted for Talent. Recognized for Results.</h1>
						<p>We are Bartech, a recruiting innovator and premier staffing firm. We serve as a strategic partner to domestic, global, and multinational companies that hire Engineering, Technical, and Professional talent. </p>
						<p>Bartech’s approach is centered on building strong, personalized relationships with both our clients and candidates. We get to know your specific hiring needs and use this knowledge to create successful connections that empower growth and success.</p>
					</>
				}
				imageURL1={`/site-assets/images/main-banner.webp`}
				imageALT1={`Main Banner`}
				imageURL2={`/site-assets/images/main-banner-overlay.jpg`}
				imageALT2={`Overlay Main Banner`}
				jobSearch={true}
				pageAnchor={`more-info-of-us`}
			/>
			
			<ImageWithVideoBanner
				global={global}
				pathPrefix={`homepage.image_with_video_banner`}
				sectionId={`what-we-do`}
				customClass={`py-5`}
				content={<>
					<h2>It's What We Do</h2>
					<p>Unlocking exceptional job opportunities for Engineering, Technical, and Professional talent, we transform our client partners' businesses.</p>
				</>
				}
				buttonTitle={`Learn More About What We Do`}
				buttonLink={`/`}
				videoLink={`https://vimeo.com/893966377`}
				bannerImage={``}
				imageURL={`/site-assets/images/what-we-do.webp`}
				imageALT={`It's what we do`}
				videoGalleryClass={`WhatWeDo`}
				pageAnchor={`more-info-of-us`}
			/>
			<LatestJobs
				global={global}
				pathPrefix={`homepage.latest_jobs`}
				customClass={`py-5`}
				post={all_jobs}
				content={<>
					<h2>Hot Jobs</h2>
					<p>Find the best tech and digital career opportunities.</p>
				</>
				}
				buttonTitle={`View All Jobs`}
				buttonLink={`/jobs/`}
				swiperID={`latestJobsSwiper`}
			/>
			<MultiColumnCTA
				global={global}
				pathPrefix={`homepage.multi_column_cta`}
				sectionId={`more-info-of-us`}
				customClass={`py-5`}
				left_content={
					<>
						<h2>Market's We Recruit</h2>
					</>
				}
				right_content={
					<>
						<h2>Business Solutions</h2>
					</>
				}
				left_post={all_sectors}
				right_post={all_solutions}
				leftColumnFilterUrl={`sectors/`}
			/>
			<LatestNews
				global={global}
				pathPrefix={`homepage.latest_news`}
				customClass={`py-5`}
				post={all_news}
				content={
					<>
						<h2>Latest News, Events, and Insights</h2>
						<p>Our tech expertise and partner-led approach allows us to deliver highly successful results for our clients so they can attract and retain unique talent for their business. Explore our latest collection of case studies, news, events, and insights</p>
					</>
				}
				buttonLink={`/news-and-insights/`}
				buttonTitle={`View All Insights`}
			/>
			<LatestAwards
				global={global}
				pathPrefix={`homepage.latest_awards`}
				customClass={`pt-5 text-center`}
				content={
					<>
						<h2>Awards & Industry Recognition</h2>
					</>
				}
				post={all_awards}
				buttonLink={``}
				buttonTitle={``}
				swiperID={`latestAwardsSwiper`}
			/>
			<GreatRecruitersWidget/>
		</>
	)
}

export async function getStaticProps({ params }) {
	const global = (await Global()).toJson();
	const awards = await Collection('awards_industry_recognition');
	const solutions = await Collection('solutions');
	const sectors = (await Collection('sectors'));
	const news = (await Collection('news'));

	const all_jobs = (await Jobs())
		.filter(function (job) {
			return (
				(new Date(job?.expires_at)) >= (new Date())
			)
		})
		.getItems().slice(0, 8);

	const all_solutions = solutions.orderBy('order').getItems();
	const all_sectors = sectors.orderBy('order').getItems();
	const all_awards = awards.orderBy('order').getItems();
	const all_news = news.orderBy('date').getItems().slice(0, 8);
	return {
		props: {
			global,
			all_awards,
			all_news,
			all_jobs,
			all_solutions,
			all_sectors
		}
	};
}
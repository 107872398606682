import SourceFlowImage from "@sourceflow-uk/sourceflowimage"
import SourceFlowText from "@sourceflow-uk/sourceflowtext"
import {
    Container,
    Row,
    Col,
    NavItem,
    Nav,
    Card
} from 'reactstrap';
import Link from "next/link"
import styles from './styles.module.scss';
import Marquee from "react-marquee-slider";

export default function LatestAwards({
    global,
    pathPrefix,
    customClass = "",
    content,
    post,
    buttonLink,
    buttonTitle
}) {
    return (
        post?.length >= 1 && (
            <section className={`${styles.root} ${customClass} overflow-hidden`} >
                <Container>
                    {content ?
                        <SourceFlowText global={global} path={`${pathPrefix}.content`} type="html">
                            {content}
                        </SourceFlowText>
                        : ""}
                    {/* <Row className="my-5 justify-content-center align-items-center"> */}
                    <div className="marquee-parent my-5 position-relative">
                        <div
                            velocity={25} className="d-flex justify-content-center">
                            {post.map((post, index) => (
                                <Card key={index} className="rounded-0 bg-transparent border-0 align-items-center mx-5">
                                    {post?.link ?
                                        <Link href={`${post?.link}`}>
                                            <a title={`${post?.title}`}>
                                                <SourceFlowImage
                                                    src={post?.image}
                                                    size="500x"
                                                    alt={post?.title}
                                                    width="100%"
                                                    height="100%"
                                                    className="card-img rounded-0 m-auto"
                                                /></a></Link> : <SourceFlowImage
                                            src={post?.image}
                                            size="500x"
                                            alt={post?.title}
                                            width="100%"
                                            height="100%"
                                            className="card-img rounded-0 m-auto"
                                        />}

                                </Card>
                            ))}
                        </div>
                    </div>
                    {/* </Row> */}
                    {buttonLink ?
                        <div className="text-center">
                            <Link href={buttonLink}>
                                <a className="primaryBtn no-arrow m-0">{buttonTitle}</a>
                            </Link>
                        </div> : ""}
                </Container>
            </section>
        )
    )
}
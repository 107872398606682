import { useState, useEffect } from 'react'
import { Container, Card } from 'reactstrap';
import styles from './styles.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from "swiper"
import Quote from "/public/site-assets/svg/quote.svg"

export default function GreatRecruitersWidget({
    global,
    pathPrefix,
    customClass = "",
}) {
    const swiperID = "greatRecruitersReviews";
    const url = "https://app.greatrecruiters.com/companies/d88c53a351269290ac45693ddb2e8a7b36cd3f44/recruiters/embed.json";

    const [data, setData] = useState(null);

    useEffect(() => {
        fetch(url)
          .then((res) => res.json())
          .then((data) => {
            setData(data)
          })
      }, [])

    const settings = {
        modules: [Navigation, Pagination, Autoplay],
        loop: true,
        spaceBetween: 30,
        slidesPerView: 1,
        speed: 500,
        autoplay: {
            delay: 5000,
        },
        pagination: {
            clickable: true,
            el: `.swiper-custom-paginations .${swiperID}`,
        },
        breakpoints: {
            767: {
                slidesPerView: 2,
            }
        }
    }
    return (
        <section className={`${styles.root} ${customClass} py-1`} >
            <Container>
                {/* <script src="https://app.greatrecruiters.com/javascripts/iframeResizerClient.js%22%3E%3C/script%3E"></script>
                <iframe width="100%" id="gr-company-iframe" src="https://app.greatrecruiters.com/companies/d88c53a351269290ac45693ddb2e8a7b36cd3f44/recruiters/embed"></iframe>

                <script dangerouslySetInnerHTML={{
                    __html: `
                    iFrameResize({
                        log: true,
                        heightCalculationMethod: 'bodyScroll'
                    }, '#gr-company-iframe');
                `}} /> */}

                <div className="wrapper rounded-4 p-4">
                    <div className="text-center my-4">
                        <h2>Great Recruiters Reviews</h2>
                    </div>
                    <div className="mt-4">
                        <Swiper {...settings} loop={data?.reviews?.length > 1}>
                            {data?.reviews?.map((post, index) => (
                                <SwiperSlide key={index}>
                                    <Card className="border-0 bg-transparent py-4 px-md-5 px-4">
                                        <span className="quote-icon">
                                            <Quote />
                                        </span>
                                        {post?.feedback &&
                                            <div className="post-description mb-3" dangerouslySetInnerHTML={{ __html: post?.feedback }} />
                                        }
                                        {post?.score &&
                                            <div className='d-flex gap-2'>
                                                <span>
                                                    <i className={`h4 bi bi-star${post?.score >= 1 ? '-fill text-warning' : ''}`}></i>
                                                </span>
                                                <span>
                                                    <i className={`h4 bi bi-star${post?.score >= 2 ? '-fill text-warning' : ''}`}></i>
                                                </span>
                                                <span>
                                                    <i className={`h4 bi bi-star${post?.score >= 3 ? '-fill text-warning' : ''}`}></i>
                                                </span>
                                                <span>
                                                    <i className={`h4 bi bi-star${post?.score >= 4 ? '-fill text-warning' : ''}`}></i>
                                                </span>
                                                <span>
                                                    <i className={`h4 bi bi-star${post?.score >= 5 ? '-fill text-warning' : ''}`}></i>
                                                </span>
                                                <span>
                                                    <strong className='small'>{post?.score}</strong>
                                                </span>
                                            </div>
                                        }
                                        <p className="m-0"><strong>{post?.recruiter_first_name} reviewed by {post?.public_candidate_name}</strong></p>
                                        {post?.candidate_job_title &&
                                            <p className="post-position"><small>{post?.candidate_job_title}</small></p>
                                        }
                                    </Card>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <div className="text-center swiper-custom-paginations mt-4">
                            <div className={`${swiperID}`} />
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    )
}